import API from "@/api";

export default {
  getList: (params) => {
    return API.requestPost({
      data:params,
      url: "api/v2/report/consumeStatistics",
      auth: true,
    },false);
  },
};
