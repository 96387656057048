<template>
  <div class="user-box">
    <search-form @search="search"></search-form>
    <consume-table :list="list"></consume-table>
    <!-- 分页管理 -->
    <el-pagination
      background
      :page-sizes="pageSizes"
      :page-size="10"
      :current-page="currentPage"
      layout="total, sizes, prev, pager, next"
      :total="page.total"
      @size-change="changeSize"
      @current-change="changePage"
    ></el-pagination>
  </div>
</template>

<script>
  import consumeTable from './components/consume-table';
  import searchForm from './components/search-form';

  import service from './api';
  import pagination from '@/mixins/pagination';
  export default {
    mixins: [pagination],
    data() {
      return {
        list: [],
        page: {},
        searchData: {}
      };
    },
    components: {
      consumeTable,
      searchForm
    },
    methods: {
      init() {
        let initParams = { pageNum: 1 };
        this.getList(initParams);
      },
      getList(params) {
        let { pageNum = 1 } = params;
        this.currentPage = pageNum;

        return service.getList(params).then(res => {
          let { records = [], total = null,  size = null} = res;
          let page = {
            total,
            pageSize:size
          };
          let list = [];
          records.forEach((item, index) => {
            let obj = {}
            obj.tempId = (params.pageNum - 1) * page.pageSize + index + 1;
            obj.allAmount = 0;
            obj.time = item.time;
            item.data.map((_item, idx) => {
              switch(_item.identity){
                case '80895395': // 全景雷达
                    obj.UNIFY = _item.amount;
                    obj.allAmount += _item.amount;
                    break;
                case '-2001427886': // 探针c
                  obj.BEHAVIOR = _item.amount;
                  obj.allAmount += _item.amount;
                  break;
                case '-933681167': // 全景档案
                  obj.ARCHIVES = _item.amount;
                  obj.allAmount += _item.amount;
                  break;
                case '1644916852': // 历史信用
                  obj.HISTORY = _item.amount;
                  obj.allAmount += _item.amount;
                  break;
                case '-175359747': // 天创黑名单
                  obj.BLACKLIST = _item.amount;
                  obj.allAmount += _item.amount;
                  break;
                case '-247944669': // 信贷保镖
                  obj.BODYGUARD = _item.amount;
                  obj.allAmount += _item.amount;
                  break;
                case '-1945335789': // 三要素
                  obj.SANYS = _item.amount;
                  obj.allAmount += _item.amount;
                  break;
                default :
                  return '';
              }
            })
            list.push(obj)
          });
          this.list = list;
          this.page = page;
        });
      },
      // 搜索
      search(data) {
        let params = { ...data, pageNum: 1 };
        this.searchData = data;
        return this.getList(params);
      }
    },
    mounted() {
      this.init();
    }
  };
</script>

<style lang="scss">
  @media screen and (max-width: 480px) {
    .el-pagination{
      overflow: auto!important;
    }
  }
</style>
