<template>
  <div class="consume-table">
    <el-table border :data="list" style="width: 100%" :row-style="{ height: 0 }" :cell-style="{ padding: '12px 0' }">
      <!-- <el-table-column prop="tempId" label="ID" align="center" width="80"></el-table-column> -->
      <el-table-column prop="time" label="时间" sortable align="center" fixed="left" min-width="110"></el-table-column>
      <el-table-column min-width="95" v-for="(table, index) in tableRows" :key="index" :prop="table.prop" :label="`${table.label}`"
        align="center">
        <template slot="header">
          {{ `${table.label}` }}<noun-help :content="`${table.label == '消费总金额' ? '所有项目' : table.label}消费总金额`" />
        </template>
        <template slot-scope="scope">
          {{ scope.row[table.prop]?scope.row[table.prop]:0 | getYuan }}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="allAmount" label="消费总金额(元)" align="center">
        <template slot="header">
          消费总金额(元)<noun-help content="AF报告消费总金额+全景雷达报告消费总金额+探针C报告消费总金额+DH报告消费总金额+指迷分数报告消费总金额+三要素查询消费总金额" />
        </template>
        <template slot-scope="scope">
          {{ scope.row.allAmount | getYuan }}
        </template>
      </el-table-column>
      <el-table-column prop="UNIFY" label="全景雷达" align="center">
        <template slot="header">
          全景雷达(元)<noun-help content="全景雷达报告消费总金额" />
        </template>
        <template slot-scope="scope">
          {{ scope.row.radar | getYuan }}
        </template>
      </el-table-column>
      <el-table-column prop="BEHAVIOR" label="探针C" align="center">
        <template slot="header">
          探针C(元)<noun-help content="探针C报告消费总金额" />
        </template>
        <template slot-scope="scope">
          {{ scope.row.TZ | getYuan }}
        </template>
      </el-table-column>
      <el-table-column prop="ARCHIVES" label="全景档案" align="center">
        <template slot="header">
          全景档案(元)<noun-help content="全景档案报告消费总金额" />
        </template>
        <template slot-scope="scope">
          {{ scope.row.TZ | getYuan }}
        </template>
      </el-table-column>
      <el-table-column prop="HISTORY" label="探针C" align="center">
        <template slot="header">
          信用历史(元)<noun-help content="探针C报告消费总金额" />
        </template>
        <template slot-scope="scope">
          {{ scope.row.TZ | getYuan }}
        </template>
      </el-table-column>
      <el-table-column prop="BLACKLIST" label="探针C" align="center">
        <template slot="header">
          天创黑名单(元)<noun-help content="探针C报告消费总金额" />
        </template>
        <template slot-scope="scope">
          {{ scope.row.TZ | getYuan }}
        </template>
      </el-table-column>
      <el-table-column prop="BODYGUARD" label="探针C" align="center">
        <template slot="header">
          信贷保镖(元)<noun-help content="探针C报告消费总金额" />
        </template>
        <template slot-scope="scope">
          {{ scope.row.TZ | getYuan }}
        </template>
      </el-table-column>
      <el-table-column prop="sanys" label="用户查询" align="center">
        <template slot="header">
          用户查询(元)<noun-help content="用户查询消费总金额" />
        </template>
        <template slot-scope="scope">
          {{ scope.row.sanys | getYuan }}
        </template>
      </el-table-column> -->
    </el-table>
  </div>
</template>

<script>
import nounHelp from '@/components/viewBar/reports/noun-help'
export default {
  components: { nounHelp },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      tableRows: [{ label: '消费总金额', prop: 'allAmount', identity: '' }, { label: '全景雷达', prop: 'UNIFY', identity: '80895395' },
      { label: '探针C', prop: 'BEHAVIOR', identity: '-2001427886' }, { label: '全景档案', prop: 'ARCHIVES', identity: '-933681167' },
      { label: '信用历史', prop: 'HISTORY', identity: '1644916852' }, { label: '天创黑名单', prop: 'BLACKLIST', identity: '1644916852' }, 
      { label: '信贷保镖', prop: 'BODYGUARD', identity: '-247944669' },{ label: '三要素检查', prop: 'SANYS', identity: '-1945335789' }, 
      { label: '借条综合', prop: 'NOTE', identity: '2402290' }, { label: '用户查询', prop: 'sanys', identity: '' }]
    }
  },
  methods: {
    handleEdit (index, row) {
      this.$emit('show-edit', { index, row });
    },
    checkLock (index, row) {
      row.showPsd = !row.showPsd;
      this.copyList.splice(index, 1, row);
    }
  },
  filters: {
    getYuan (q) {
      if (q == 0) {
        return '-'
      }
      return (q / 100).toFixed(2)
    }
  }
};
</script>

<style lang="scss" scoped></style>
